<template>
  <div class="form-section" v-if="amReady" style="width: 1100px; margin-top: 20px;">

    <h4>Tax Report</h4>
    <exit-button></exit-button>

    <el-tabs type="border-card" class="">
      <el-tab-pane label="Config">
         <span slot="label">
          <img src="/img/flags/canada.png" width="42px">
        </span>
        <canada-tab :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"></canada-tab>
      </el-tab-pane>
    </el-tabs>

    </div>
</template>

<script>
import module from './../../index.js'

export default {
  components: {
    'canada-tab': () => import('./canada-tab/View'),
    'exit-button': () => import('@/components/exit-button/View')
  },
  beforeMount() {this.getFormData()},
  data() {
    return {
      module: {...module},
      control: this,
      amReady: false,
      requestOn: false,
      editable: !this.editMode,
      requestErrorMessage: null,
      radioOptions: [
        {
          label: 'Yes',
          value: 1,
          link_label: ''
        },
        {
          label: 'No',
          value: 0,
          link_label: ''
        }
      ],
    }
  },
  methods: {
    goToDashboard () {
      $router.push({name: 'portal-cpanel->dashboard'})
    },
    getFormData() {
      let promises = []
      Promise.all(promises).then((values) => {
        this.amReady = true
      })
    },
    ...$mapActions('Accounting-Report-api', {
      userDataAuthFields: 'userDataAuthFields',
      getEntityDropDownList: 'getDropDownList',
      /*
      updateFile: 'updateFile',
      updateGallery: 'updateGallery',
      removeGalleryImage: 'removeGalleryImage'*/
    }),
    /* removeFromGallery (file) {
      let params = {
        id: this.dataToBind.id,
        fileId: file.id,
        parentId: file.parent_id
      }
      this.removeGalleryImage(params).then(r => {
          if (this.onEditCallback) {
            this.onEditCallback(r.data, 'gallery', false)
          }
          $services['notify']['success']('Picture Removed')
      })
    },
    async uploadFile (entityId, fieldName, apiName) {
      if (this.dataModel.hasOwnProperty(fieldName) && this.dataModel[fieldName]) {
        let data =  {}
        data[fieldName] = this.dataModel[fieldName]

        let fileParams = {data, id: entityId}
        await this[apiName](fileParams).then(r => {
          $services['notify']['success']((apiMethod == 'update') ? 'Updated' : 'Created')
        }).catch(error => {
          $services['notify']['danger']('File Not Uploaded')
        })
      }
    },
    async uploadGallery (entityId) {
      if (this.dataModel.hasOwnProperty('gallery') && this.dataModel.gallery) {
        let galleryParams = {
          data: {
            gallery: this.dataModel.gallery
          },
          id: entityId
        }

        await this.updateGallery(galleryParams).then(r => {
          $services['notify']['success']('Gallery Uploaded')
        }).catch(error => {
          $services['notify']['danger']('Gallery Not Uploaded')
        })
      }
    },*/
    async createModule() {
      this.validateFields().then( async result => {
        if (!result) { this.requestErrorMessage = null; return}

        this.requestOn = true
        let context = this;
        let apiMethod = (this.editMode) ? 'update' : 'create';
        let apiParams = {data: {...this.dataModel}};
        if (this.editMode) apiParams.id = this.dataToBind.id;


        /* Images Handleing - removing files they are handled alone
        delete apiParams.data.file
        delete apiParams.data.picture
        delete apiParams.data.gallery

        if (apiMethod == 'update') {
            await this.uploadFile(this.dataToBind.id, 'file', 'updateFile') // Updating File
            await this.uploadFile(this.dataToBind.id, 'picture', 'updateFile') // Updating Picture
            await this.uploadGallery(this.dataToBind.id) // Updating Gallery
        }*/

        $store.dispatch('Accounting-Report-api/' + apiMethod, apiParams).then(async response => {
          let entity = response.data
          this.amReady = false

          $services['notify']['success']((apiMethod == 'update') ? 'Updated' : 'Created')
          /* if (apiMethod == 'create') {
             await this.uploadFile(entity.id, 'file', 'updateFile') // Updating File
             await this.uploadFile(entity.id, 'picture', 'updateFile') // Updating Picture
             await this.uploadGallery(entity.id) // Updating Gallery
          } */

          $vue.nextTick(() => {
            context.amReady = true
            if (context.editMode) {
              if (context.onEditCallback) context.onEditCallback(response.data)
            } else {
              if (context.onCreateCallback) context.onCreateCallback(response.data)
            }
          })

        }).catch(error => {
          console.log('catch error', error)
          this.validData = false
          if (error.response.data.errorMessage) {
            $store.state['App-App-state'].generalDialogStatus = true
            $store.state['App-App-state'].generalDialogMessage = error.response.data.errorMessage
            $store.state['App-App-state'].generalDialogTitle = "Attention"

            this.requestErrorMessage = error.response.data.errorMessage
          } else {
            this.setfieldsErrorMessage({
              fieldsErrors: error.response.data
            });
          }
        }).finally(() => {this.requestOn = false})
      })
    }
  },
  mixins: [$mixins['fieldController'], $mixins['moduleForm']],
}
</script>
